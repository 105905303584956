.dropdown-toggle-custom {
  background-color: transparent;
  color: black;
  padding: 0.3rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.dropdown-toggle-custom[data-is-submenu='true'] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #495057 !important;
  padding: 0.35rem 1.5rem !important;
}

.dropdown-toggle-custom[data-is-submenu='true'] > .caret-icon {
  border-color: #495057 !important;
}

.dropdown-toggle-custom[data-is-submenu='true']:hover {
  background-color: #f8f9fa;
}

.dropdown-toggle-custom:hover {
  background-color: rgb(229, 238, 241);
  color: black;
}

/* ícone de seta */
.caret-icon {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-45deg);
  margin-left: auto;
}

.dropdown-menu-custom {
  margin-left: -0.2rem !important;
  width: auto;
}

.submenu-item {
  color: black;
  padding: 10px;
  cursor: pointer;
}

.submenu-item-hover {
  background-color: rgb(35, 119, 136) !important;
  color: white !important;
}

.custom-divider {
  width: 90%;
  margin: 0.3rem auto;
  border-top: 1px solid #ddd;
}

.area-botao-add-widget {
  display: flex;
  align-items: center;
}

button:is(.botao-add-widget) {
  background-color: rgb(35, 119, 136);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0 0 1rem 1rem;
}

.fonte-add-widgets {
  font-weight: 900 !important;
  margin: 0 0 1rem 1rem;
}
