a,
button.dropdown-item {
  cursor: pointer;
}

// Custom box filter in dialog component
.dialog {
  .boxFilter {
    top: 0%;
    .panel {
      height: 100vh;
    }
  }
}
//Custom NavDropdown for change module
.modulos-container {
  width: 100%;
  min-width: 290px;
  margin: 0 auto;
  padding: 15px;
}
.module-box {
  padding-bottom: 15px;
  padding-top: 15px;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  &:hover:not(.disabled),
  &.active:not(.disabled) {
    background-color: rgba(23, 132, 199, 0.9);
    .icon-box,
    .legend-icon-box {
      color: #fafbfc;
    }
  }
  &.disabled {
    position: relative;
    .icon-box,
    .legend-icon-box {
      opacity: 0.65;
    }
    &:before {
      opacity: 0;
      -webkit-transition: all 0.25s linear;
      transition: all 0.25s linear;
      content: attr(data-textdisabled);
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      font-size: 12px;
      padding: 5px;
      border: 1px solid #cccccc;
      border-radius: 2px;
      background-color: #ffffff;
      z-index: 2;
      max-width: 100%;
      color: initial;
    }
    &:after {
      content: '';
      -webkit-transition: all 0.25s linear;
      transition: all 0.25s linear;
      left: 0;
      top: 0;
      position: absolute;
      z-index: 1;
      opacity: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(34, 34, 34, 0.2);
    }
    &:hover {
      &:before,
      &:after {
        opacity: 1;
      }
    }
  }
}
.icon-box {
  margin-bottom: 10px;
  color: rgb(73, 80, 87);
}
.legend-icon-box {
  color: #888888;
  padding-left: 10px;
  padding-right: 10px;
  display: block;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pad-no,
.pad-no .dropdown-menu {
  padding: 0;
}
.pad-custom {
  &:nth-child(odd) {
    padding-right: 0;
  }
  &:nth-child(even) {
    padding-left: 0;
  }
}
.text-ellipis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
//Custom Widget Dashboard
.card.widget {
  cursor: pointer;
  &[disabled] {
    opacity: 0.4 !important;
    filter: grayscale(1);
    cursor: default;
  }
  &:hover {
    opacity: 0.95;
  }
  .pv-lg.col-8 {
    & > div.text-uppercase {
      min-height: 42px;
    }
  }
}
//Custom Widget Dashboard - listgroup block style
.list-group-block {
  .text-bold {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .list-title-item {
    vertical-align: middle;
    width: calc(100% - 125px);
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.list-item-comum {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.text-hover-link {
  &:hover {
    span {
      cursor: pointer;
      color: #337ab7;
    }
  }
}

.vertical-middle {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
  display: flex;
  text-align: center;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.popover {
  max-width: -moz-fit-content !important; /* Firefox/Gecko */
  max-width: -webkit-fit-content !important; /* Chrome */
  z-index: 13000 !important;
}

.dialog {
  .filter {
    .form-group {
      margin-bottom: 0;
    }
  }
}

.pull-right {
  float: right;
}

.Gantt1 {
  .gantt_side_content {
    margin-bottom: 7px;
  }
  .gantt_task_link .gantt_link_arrow {
    margin-top: -3px;
  }
  .gantt_side_content.gantt_right {
    bottom: 0;
  }
}

.Gantt2 {
  .gantt_task_line,
  .gantt_line_wrapper {
    margin-top: -9px;
  }
  .gantt_side_content {
    margin-bottom: 7px;
  }
  .gantt_task_link .gantt_link_arrow {
    margin-top: -12px;
  }
  .gantt_side_content.gantt_right {
    bottom: 0;
  }
}

.Gantt3 {
  .gantt_task_line,
  .gantt_line_wrapper {
    margin-top: -16px;
  }
  .gantt_side_content {
    margin-bottom: 7px;
  }
  .gantt_task_link .gantt_link_arrow {
    margin-top: -19px;
  }
  .gantt_side_content.gantt_right {
    bottom: 0;
  }
}

//custom loading first time
/*html,
body,
#root,
#root > div,
main,
main > div.content-wrapper {
	height: 100%;
}*/

/*Overide z-index tooltip*/
.tooltip {
  &.show {
    z-index: 12000;
  }
}

.cor-sucesso {
  color: #46be8a;
}

.cor-aviso {
  color: orange;
}

.cor-erro {
  color: #f96868;
}

.containerDisabled .ql-container {
  background: rgb(237, 241, 242);
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  // width: 100% !important;
  height: 100% !important;
}

.main-menu {
  cursor: pointer;
  margin: 4px 15px 0 0;
}

.add-btn-line {
  transition: 0.2s ease-in-out;
}

.add-btn-line:hover {
  background: #3b82ec;
  svg,
  span {
    transition: 0.2s ease-in-out;
    color: #fff !important;
  }
}

.skeleton {
  animation: 1.5s skeleton ease-in-out 0.5s infinite;
}

@keyframes skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.bg-green-dark {
  background-color: #1d7789 !important;
}

.chips-wrapper {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.chips-wrapper::-webkit-scrollbar {
  /* WebKit */
  display: none; /* Safari and Chrome */
}

#filter-left-arrow,
#filter-right-arrow {
  position: relative;
  background: rgba(255, 255, 255, 0.3);
}

#filter-left-arrow::after,
#filter-right-arrow::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

#filter-left-arrow::after {
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.98) 20%,
    rgba(255, 255, 255, 0) 80%
  );
}

#filter-right-arrow::after {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.98) 20%,
    rgba(255, 255, 255, 0) 80%
  );
}

* {
  scroll-margin-top: 82px;
}

.quill-text p {
  padding: 0;
  margin: 0;
}

.quill-text span,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  color: #495057 !important;
}

.quill-text a {
  text-decoration: underline;
  color: #1d7789 !important;
}

.b-grid-subgrid {
  background-image: none !important;
}

.b-grid-header,
.b-grid-cell {
  min-width: 35px !important;
}

.b-grid-row.primary-light {
  background-color: #f2fdff !important;
}

.b-gantt .b-grid-row .b-grid-cell {
  transition: background-color 0.2s;
}

.b-gantt-critical-paths
  .b-grid-subgrid-locked
  .b-grid-row.b-critical
  .b-grid-cell {
  background-color: #ffcccc;
}

.b-gantt-critical-paths
  .b-grid-subgrid-locked
  .b-grid-row.b-critical.b-selected {
  background-color: #ff9999;
}

.b-sch-dependency {
  stroke-width: 2 !important;
}

.new-list-tree::-webkit-scrollbar {
  width: 18px;
}

.new-list-tree::-webkit-scrollbar-track {
  background: #fff;
}

.new-list-tree::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 20px;
  border: 5px solid #fff;
  min-height: 40px;
}

.new-list-tree::-webkit-scrollbar-thumb:hover {
  background-color: #acacac;
}

.b-farol-cell div {
  width: 100%;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-1
  .b-gantt-task-content {
  background-color: #8b008b !important;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-2
  .b-gantt-task-content {
  background-color: #0000ff !important;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-3
  .b-gantt-task-content {
  background-color: #458039 !important;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-4
  .b-gantt-task-content {
  background-color: #ffd700 !important;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-5
  .b-gantt-task-content {
  background-color: #d4353e !important;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-6
  .b-gantt-task-content {
  background-color: #a9a9a9 !important;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-7
  .b-gantt-task-content {
  background-color: #000000 !important;
}

.b-gantt-task-wrap.b-milestone-wrap
  .b-gantt-task.task-gantt-bg-8
  .b-gantt-task-content {
  background-color: #d6d6d6 !important;
}

.b-ganttbase.b-gantt-critical-paths
  .b-gantt-task.b-critical
  :not(.b-milestone-wrap) {
  background-color: #d25d3c !important;
  animation-name: fadeIn;
  animation-duration: 0.7s;
}

.b-gantt-task-wrap.b-milestone-wrap .b-gantt-task {
  background-color: transparent !important;
}

.dropdown-toggle:after {
  margin-left: 2px !important;
}

#matriz-portfolio-projetos {
  max-height: calc(100vh - 130px);
}

#matriz-portfolio-projetos:fullscreen {
  height: 100vh;
  max-height: 100vh;
  background-color: #ffffff;
}

.table-borderless td,
.table-borderless th {
  border: none;
}

.fluxo-aprovacao-tooltip {
  max-width: 300px;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #1d7789 !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #ccdde0 !important;
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff;
}

.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f7f9fc;
}

.d-tree-container {
  list-style: none;
  padding: 0;
}

.d-tree-node {
  padding: 0.75rem 1.25rem;
}

.card-meta-container {
  background-color: #ffffff;
}

.card-meta-container:fullscreen {
  overflow: auto;
}
#webpack-dev-server-client-overlay {
  display: none !important;
}

.bp-widget-web {
  z-index: 14000 !important;
  bottom: -15px !important;
  right: 5px !important;
}
