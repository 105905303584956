/* Generated by Glyphter (http://www.glyphter.com) on  Wed Aug 16 2017*/
@font-face {
  font-family: 'stratec farois';
  src: url('../fonts/stratec-farois.eot');
  src: url('../fonts/stratec-farois.eot?#iefix') format('embedded-opentype'), url('../fonts/stratec-farois.woff') format('woff'),
    url('../fonts/stratec-farois.ttf') format('truetype'), url('../fonts/stratec-farois.svg#stratec-farois') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'stratec farois';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-desatualizado:before {
  content: '\0041';
}
.icon-alcancada:before {
  content: '\0042';
}
.icon-atencao:before {
  content: '\0043';
}
.icon-nao-alcancada:before {
  content: '\0044';
}
.icon-com-folga:before {
  content: '\0045';
}
.icon-analizar:before {
  content: '\0046';
}
.icon-relatorio:before {
  content: '\0047';
}
.icon-nao-iniciada:before {
  content: '\0048';
}
.icon-no-prazo:before {
  content: '\0049';
}
.icon-inicio-fora:before {
  content: '\004a';
}
.icon-atrasada:before {
  content: '\004b';
}
.icon-concluida:before {
  content: '\004c';
}
.icon-concluida-fora:before {
  content: '\004d';
}
.icon-cancelada:before {
  content: '\004e';
}
.icon-investimento-ok:before {
  content: '\004f';
}
.icon-invetimento-abaixo:before {
  content: '\0050';
}
.icon-alto:before {
  content: '\0051';
}
.icon-medio:before {
  content: '\0052';
}
.icon-baixo:before {
  content: '\0053';
}
.icon-home:before {
  content: '\0054';
}
.icon-tarefas:before {
  content: '\0055';
}
.icon-gerenciar:before {
  content: '\0056';
}
.icon-acompanhar:before {
  content: '\0057';
}
.icon-cadastrar:before {
  content: '\0058';
}
.icon-ferramentas:before {
  content: '\0059';
}
.icon-configuracoes:before {
  content: '\005a';
}
.icon-desatualizado:before {
  content: '\0061';
}
.icon-alcancada:before {
  content: '\0062';
}
.icon-atencao:before {
  content: '\0063';
}
.icon-nao-alcancada:before {
  content: '\0064';
}
.icon-com-folga:before {
  content: '\0065';
}
.icon-analizar:before {
  content: '\0066';
}
.icon-relatorio:before {
  content: '\0067';
}
.icon-nao-iniciada:before {
  content: '\0068';
}
.icon-no-prazo:before {
  content: '\0069';
}
.icon-inicio-fora:before {
  content: '\006a';
}
.icon-atrasada:before {
  content: '\006b';
}
.icon-concluida:before {
  content: '\006c';
}
.icon-concluida-fora:before {
  content: '\006d';
}
.icon-cancelada:before {
  content: '\006e';
}
.icon-investimento-ok:before {
  content: '\006f';
}
.icon-invetimento-abaixo:before {
  content: '\0070';
}
.icon-alto:before {
  content: '\0071';
}
.icon-medio:before {
  content: '\0072';
}
.icon-baixo:before {
  content: '\0073';
}
.icon-home:before {
  content: '\0074';
}
.icon-tarefas:before {
  content: '\0075';
}
.icon-gerenciar:before {
  content: '\0076';
}
.icon-acompanhar:before {
  content: '\0077';
}
.icon-cadastrar:before {
  content: '\0078';
}
.icon-ferramentas:before {
  content: '\0079';
}
.icon-configuracoes:before {
  content: '\007a';
}

.hidePlusIcon div[data-column-name='add'] {
  visibility: hidden;
}
