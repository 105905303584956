.sidebar {
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  transition: $sidebar-transition;
  background: $sidebar-bg;
  border-right: $sidebar-border;
}

.sidebar-content {
  background: $sidebar-bg;
  transition: $sidebar-transition;

  .sidebar-sticky & {
    border-right: $sidebar-border;
  }
}

.sidebar-sticky {
  z-index: 150;
  
  .sidebar-nav {
    padding-bottom: 0;
  }

  .sidebar-content {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: $sidebar-width;
  }
}

.sidebar-nav {
  margin: 20px 0;
  padding-bottom: 3.5rem;
  padding-left: 0;
  list-style: none;
}

// Sidebar links
.sidebar-link,
a.sidebar-link {
  display: flex;
  align-items: center;
  padding: $sidebar-link-padding;
  color: $sidebar-link-color;
  font-weight: $sidebar-link-font-weight;
  background: $sidebar-link-bg;
  transition: background 50ms ease-in-out;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  i,
  img,
  svg {
    color: $sidebar-link-icon-color;
    opacity: .8;
  }
}

// Sidebar nested links
.sidebar-dropdown {
  .sidebar-dropdown {
    .sidebar-link {
      padding: $sidebar-link-child-second-padding;
    }

    .sidebar-dropdown {
      .sidebar-link {
        padding: $sidebar-link-child-third-padding;
      }
    }
  }
}

.sidebar-link:focus {
  outline: 0;
}

.sidebar-link:hover {
  color: $sidebar-link-hover-color;
  font-weight: $sidebar-link-hover-font-weight;
  opacity: .5;
  i,
  svg {
    color: $sidebar-link-icon-hover-color;
    opacity: .5;
  }
}

.sidebar-item.active > .sidebar-link,
.sidebar-item.active .sidebar-link:hover {
  color: $sidebar-link-active-color;


  i,
  svg {
    color: $sidebar-link-icon-active-color;
    opacity: .5;
  }
}

// Children
.sidebar-dropdown .sidebar-link {
  padding: $sidebar-link-child-padding;
  color: $sidebar-link-child-color;
  background: $sidebar-link-child-bg;
  font-weight: $sidebar-link-child-font-weight;
  
}

.sidebar-dropdown .sidebar-item .sidebar-link:hover {
  color: $sidebar-link-child-hover-color;
  font-weight: $sidebar-link-hover-font-weight;
}

.sidebar-item.active .sidebar-link {
  color: $sidebar-link-child-active-color;
  background: $sidebar-link-child-active-bg;
  font-weight: $sidebar-link-active-font-weight;
}

.sidebar-item .sidebar-link.active {
  color: $sidebar-link-child-active-color;
  background: $sidebar-link-child-active-bg;
  font-weight: $sidebar-link-active-font-weight;
}

// Chevron
.sidebar [data-toggle="collapse"] {
  position: relative;
}

.sidebar [data-toggle="collapse"]:before {
  content: " ";
  border: solid;
  border-width: 0 .1rem .1rem 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  position: absolute;
  top: 45% !important;
  right: 1.7rem;
  transition: all .2s ease-out;
}

.sidebar [aria-expanded="true"]:before,
.sidebar [data-toggle="collapse"]:not(.collapsed):before {
  transform: rotate(-135deg);
  top: 1.4rem;
}

.sidebar .sidebar-dropdown [data-toggle="collapse"]::before {
  top: 1rem;
}

.sidebar .sidebar-dropdown [aria-expanded="true"]:before,
.sidebar .sidebar-dropdown [data-toggle="collapse"]:not(.collapsed):before {
  top: 45% !important;
}

// Sidebar brand
.sidebar-brand {
  font-weight: $sidebar-brand-font-weight;
  padding: $sidebar-brand-padding-y $sidebar-brand-padding-x;
  color: $sidebar-brand-color;
  display: flex;
  flex-shrink: 0;
  max-width: 100%;
  height: 60px;

  &:hover {
    text-decoration: none;
    color: $sidebar-brand-color;
  }

  &:focus {
    outline: 0;
  }

  svg,
  .feather {
    color: $sidebar-brand-icon-color;
    min-height: 24px;
    min-width: 24px;
  }
}

.sidebar-link-collapsed {
  display: block;
  padding: 15px;
  color: $sidebar-link-color;
  font-weight: $sidebar-link-font-weight;
  background: $sidebar-link-bg;
  transition: background 50ms ease-in-out;
  position: relative;
  text-decoration: none !important;
}  

.sidebar-link-collapsed:hover {
  color: $sidebar-link-hover-color;
  
  font-weight: $font-weight-bold;
  i,
  svg {
    color: $sidebar-link-icon-hover-color;
    opacity: .5;
  }
}

.collapsed-menu hr {
  background: #677986;
  margin: 0 15px;
}

.sidebar {
  &.toggled {
    min-width: 80px;
    max-width: 80px;

    // .sidebar-brand span {
    //   display: none;
    // }

    // .sidebar-nav .sidebar-item span::before {
    //   display: none;
    // }

    // .sidebar-nav .sidebar-item span span {
    //   display: none;
    // }

    .sidebar-content {
      width: 80px;
    }    
  }
}

// Sidebar header
.sidebar-header {
  background: $sidebar-header-color;
  color: $sidebar-header-color;
  padding: $sidebar-header-padding;
  font-size: $sidebar-header-font-size;
  text-transform: $sidebar-header-text-transform;
}

// Sidebar bottom
.sidebar-bottom {
  transition: $sidebar-transition;
  padding: $spacer;
  width: inherit;
  font-size: 0.8rem;
  bottom: 0;
  left: 0;
  position: fixed;
  background: $sidebar-bottom-bg;
  min-width: $sidebar-width;
  max-width: $sidebar-width;
  color: $sidebar-bottom-color;
  border-right: $sidebar-border;
  margin-left: 0;

  h5 {
    color: $sidebar-bottom-color;
  }
}

// Badge
.sidebar-item {
  position: relative;

  .sidebar-badge {
    position: absolute;
    right: 20px;
    top: 14px;
  }

  .sidebar-item {
    .sidebar-badge {
      top: 10px;
    }
  }
}

.sidebar-item-hover {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 100%; 
  min-width: 220px;  
}

.sidebar-item-hover .collapse { 
 
}

.sidebar-item:hover{
  .sidebar-item-hover {
    visibility: visible;
  }
}

.main_scrollbar > .ps__rail-x {
  display: none !important;
}

.main_scrollbar > .ps__rail-y {
  display: none !important;
}