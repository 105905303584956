.navbar-landing {
  background: darken($primary, 35%);
  padding: $spacer*0.5 $spacer*1.5;
  font-size: 1rem;

  @include media-breakpoint-down(md) {
    width: 100% !important;
  }
}

.landing-intro {
  background: darken($primary, 35%);
  color: $white;
  overflow: hidden;
}

.landing-intro-title {
  font-size: 2.5rem;
  color: $white;
}

.landing-intro-subtitle {
  font-size: 1.0875rem;
  color: $gray-300;
}

.landing-intro-img {
  box-shadow: $box-shadow-lg;

  &:before {
    background: linear-gradient(transparent,rgba(0,0,0,.15));
    bottom: 0;
    left: 0;
    position: absolute;
    content: " ";
    z-index: 1;
    display: block;
    width: 100%;
    height: 250px;
  }

  img {
    margin-bottom: -5rem;
  }
}

.landing-intro-shape {
  z-index: 2;
  position: absolute;
  overflow: hidden;
  margin-top: -14.5vw;
  
  @include media-breakpoint-up(lg) {
    margin-top: -12vw;
  }
}

.landing-img {
  box-shadow: rgba(darken($primary, 25), 0.15) 0px 4px 12px 0px;
  transition: all .15s ease-in-out;
  
  &:hover {
    transform: scale(1.035);
  }
}

.landing-feature {
  align-items: center;
  background: darken($body-bg, 3);
  border-radius: 50%;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-right: $spacer;
  width: 60px;

  svg {
    width: 28px;
    height: 28px;
    color: darken($primary, 5);
  }
}

.landing-stars {
  color: $yellow;
}

.landing-quote {
  p {
    line-height: 1.8rem;

    span {
      display: inline;
      background: lighten($primary, 35%);
      padding: .0625em .125em;
    }
  }
}

.landing-footer {
  background: darken($primary, 41%);
  color: $white;
  overflow: hidden;
}

.landing-footer-container {
  margin-top: 0;
  
  @include media-breakpoint-up(xl) {
    margin-top: -5vw;
  }
}
