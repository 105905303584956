.tab {
  margin-bottom: $spacer*2;

  .nav-tabs {
    border-bottom: $tab-link-border;
  }

  .nav-tabs .nav-link {    
    color: $tab-link-color;
    padding: $tab-link-padding;    
    font-weight: $tab-link-font-weight;
    
    &.active {      
      color: $primary;
      border-bottom: $tab-link-border;
      border-bottom-color: $primary;
    }

    &:hover:not(.active) {
      color: $link-color;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .tab-content {
    background: $white;
    padding: $tab-content-padding;
    box-shadow: $box-shadow;
    // border-radius: $tab-content-border-radius;
    // border-style: solid;
    // border-width: 0 1px 1px 1px;
    // border-color: $tab-content-border-color;

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@each $color, $value in $theme-colors {
  .tab-#{$color} {
    @include tab-variant($value);
  }
}

.tab-title {
  font-size: $font-size-lg;
}

.tab-vertical .nav-tabs {
  float: left;
  flex-direction: column;

  .nav-link {
    @include border-left-radius($border-radius);
    @include border-right-radius(0);
  }
}

.tab-vertical .tab-content {
  overflow: auto;
}
